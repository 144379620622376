<template>
  <div class="main">
    <div class="modal">
      <div class="modal-left">
        <div class="bonus-card">
          <div class="bonus-img">
            <img src="@/assets/img/banner.webp" alt="monkey" />
          </div>
          <div class="bonus-desc">
            <h3>Оставайтесь неукротимым</h3>
            <p>Зарегистрируйтесь и получите приветственный бонус</p>
          </div>
        </div>
        <div class="bonus-card_mobile">
          <img class="logo_mobile" src="@/assets/img/logo_mobile.webp" alt="logo">
          <img width="100%" height="auto" class="banner" src="@/assets/img/banner_mobile.webp" alt="banner">
        </div>
      </div>
      <div class="registration-form modal-right">
        <div class="form-header">
          <h2 class="active">Регистрация</h2>
        </div>
        <form
          action="#"
          id="registrationForm"
          :class="{'form_disabled': isRequestSending}"
          @submit.prevent="submitForm"
        >
          <div class="form-group" style="width: 100%">
            <input type="tel" id="phone" placeholder="Номер телефона" v-model="phone" required />
          </div>
          <div class="form-group" style="width: 100%">
            <input type="password" id="password" placeholder="Пароль" v-model="password" required />
            <div class="passwordShow">
              <svg class="icon w-6 h-6 text-tertiary" viewBox="0 0 32 32">
                <path
                  d="M2.695 15.617c-.091-.441.037-.733.386-.877.525-.215.899.076 1.26.5.2.232 1.382 1.46 3.701 2.471 1.902.829 4.575 1.495 8.042 1.495 3.878 0 6.475-.715 8.255-1.495a10.79 10.79 0 0 0 3.459-2.471c.905-1.003 1.668-.205 1.516.61-.066.353-.45 1.402-1.41 2.54-.513.608-1.262 1.224-2.245 1.853 1.043 2.254 1.296 3.515.758 3.783-.538.269-1.687-.598-3.446-2.598-.936.288-1.826.506-2.668.653a21.82 21.82 0 0 1-2.735.287c-.382 2.6-.876 3.902-1.483 3.902-.607 0-1.1-1.301-1.481-3.902a17.458 17.458 0 0 1-2.675-.287 16.662 16.662 0 0 1-2.56-.676c-1.939 2.142-3.155 3.1-3.65 2.873-.493-.226-.166-1.589.983-4.09a9.505 9.505 0 0 1-2.512-2.031c-.711-.812-1.207-1.659-1.493-2.541h-.002Z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="promo-check">
            <p>
              Введите реферальный код / промокод
              <span>
                  <svg
                    class="icon size-4 transition ease-out sm:cursor-pointer"
                    viewBox="0 0 32 32"
                  >
                    <path
                      d="M20.1912 6.1001L9.79119 16.5001L20.1912 26.9001L23.2088 23.8825L15.8264 16.5001L23.2088 9.1177L20.1912 6.1001Z"
                    ></path>
                  </svg>
                </span>
            </p>
          </div>
          <div class="form-group promo-code">
            <input type="text" id="promo" v-model="promocode" />
          </div>
          <div class="form-check">
            <label class="perm-check">
              <input type="checkbox" id="check" checked />
              <p>
                Я принимаю <a href="#">Пользовательское соглашение</a> и
                подтверждаю, что мне не менее 18 лет
              </p>
            </label>
          </div>
          <div class="form-check">
            <label class="perm-check">
              <input type="checkbox" id="checkBC" checked />
              <p>
                Я согласен получать рекламные предложения от<span
              >BC.GAME.</span
              >
              </p>
            </label>
          </div>
          <button type="submit" class="submit-btn">Регистрация</button>

        </form>
        <div class="sign-in">
          <p>Уже есть аккаунт? <a href="#">Войти</a></p>
        </div>
        <div class="block-close">
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            fill="#b3bec1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L9.06066 8L12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L8 6.93934L4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L6.93934 8L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L8 9.06066L11.4697 12.5303Z"
              fill="#b3bec1"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      telCode: '+7',
      phone: '',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'KOD',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
